import pip1 from 'images/profile/pip1.svg';
import pip2 from 'images/profile/pip2.svg';
import pip3 from 'images/profile/pip3.svg';
import pip4 from 'images/profile/pip4.svg';
import pip5 from 'images/profile/pip5.svg';
import pip6 from 'images/profile/pip6.svg';

export const TOKEN: string = '$MOKA';
export const CUPS: string = 'In-game soft currency';

//#region LANDING SECTION IDs
export const EXPLORE_SECTION_ID:string = 'explore-section';
export const HERO_EXPLORE_SECTION_ID: string = 'hero-explore-section';
export const WIN_TO_EARN_SECTION_ID: string = 'win-to-earn-section';
export const MARKETPLACE_SECTION_ID:string = 'marketplace-section';
export const CREATOR_PROGRAM_SECTION_ID: string = 'creator-program-section';
export const PARTNERS_SECTION_ID: string = 'partners-section';
export const MISSION_SECTION_ID: string = 'mission-section';
//#endregion

//#region TOURNAMENTS SECTION IDs
export const TOURNAMENTS_HERO_ID: string = 'tournaments-hero-section';
export const ESPORTS_ECOSYSTEM_ID: string = 'esports-ecosystem-section';
export const CHAMPIONS_SERIES_ID: string = 'champions-series-section';
export const TOURNAMENTS_DETAILS_ID:string = 'tournaments-details-section';
export const ESPORTS_STREAMING_ID:string = 'esports-streaming-section';
export const SPONSORSHIPS_OPPORTUNITIES_ID:string = 'sponsorships-opportunities-section';
export const SPONSORSHIPS_TIERS_ID:string = 'sponsorships-tiers-section';
export const MLCS_ID:string = 'mlcs-section';
//#endregion

//#region COLLECTION SECTION IDs
export const COLLECTION_HERO_SECTION:string = 'collection-hero-section';
export const COLLECTION_MARKETPLACE_SECTION:string = 'collection-marketplace-section';
//#endregion

//#region THE-GAME SECTION IDs
export const THE_MOKENS_SECTION_ID:string = 'the-mokens-section';
export const ALBUM_SECTION_ID:string = 'album-section';
export const TOURNAMENTS_SECTION_ID:string = 'tournaments-section';
export const MOKENS_UNIVERSE_SECTION_ID:string = 'universe-section';
export const COMMUNITY_SECTION_ID:string = 'community-section';
export const FAQ_SECTION_ID: string = 'faq-section';
//#endregion

//#region UNUSED SECTIONS
export const COMING_SOON_SECTION_ID: string = 'coming-soon-section';
export const JOIN_BETA_SECTION_ID:string = 'register-section';
export const TOKENOMICS_SECTION_ID: string = 'tokenomics-section';
//#endregion

//#regionTOKENS
export const TOKEN_ADDR: string = '0xE12Ed7BE67EeD7889446d5a0974e278EBb87d70F';
export const CUPS_ADDR: string = '0x7aefe02441b123c3c32466cd9db4c578618f';
//#endregion

//#region NAVIGATION
export const PASSWORDLESS_URL: string = `/passwordless`;
export const COLLECTION_URL: string = `/collection/`;
export const TOURNAMENTS_URL: string = `/tournaments/`;
export const SIGNUP_URL: string = `${PASSWORDLESS_URL}/signup`;
export const LOGIN_URL: string = `${PASSWORDLESS_URL}/login`;
export const LINK_URL: string = `${PASSWORDLESS_URL}/link`;
export const PROFILE_URL: string = '/profile';
export const SIGN_UP_URL: string = '/sign-up';
export const THE_GAME_URL: string = '/the-game/';
export const HOME_URL: string = '/';
//#endregion

export const ELIXIR_URL: string = 'https://launcher.elixir.app/games/mokens-league';

//#region METAMASK SIGN-UP STAGES
export const NETWORK_ERROR: number = -1;
export const NEW_PROFILE: number = 1;
export const TERMS_AND_CONDITIONS: number = 2;
export const LINK_WALLET: number = 3;
export const LINKING_SUCCESS: number = 4;
//#endregion

export const POLYGON_NETWORK_NAME: string = 'Polygon Mainnet';
export const POLYGON_NETWORK_CHAIN_ID: number = 137;
export const POLYGON_NETWORK_CHAIN_ID_HEX: string = '0x89';
export const POLYGON_NETWORK_CURRENCY_SYMBOL: string = 'MATIC';
export const POLYGON_NETWORK_RPC_URLS: string[] = ['https://polygon-rpc.com/'];
export const POLYGON_NETWORK_BLOCK_EXPLORER_URLS: string[] = ['https://polygonscan.com/',];
export const POLYGON_NETWORK_DECIMALS: number = 18;

export const METAMAKS_MOBILE_URL: string = 'https://metamask.app.link/dapp/www.mokensleague.com/sign-up';

export const AUDIT_REPORT_URL: string = 'https://blog.coinfabrik.com/auditoria-smart-contracts/mokens-league-audit/';

export const USER_LOCAL_STORAGE_KEY: string = 'mluser';

export const PROFILE_ICONS = [pip1, pip2, pip3, pip4, pip5, pip6];

export const S3_BUCKET_URL = 'https://d1ib7ca85npmn3.cloudfront.net/';
export const TWITCH_URL = 'https://www.twitch.tv/mokensleague';

export const LOGIN_FLOW = 'login';
export const LOGIN_ERROR_MSG = 'This provider is not linked to any account';
export const SINGUP_FLOW = 'signup';
export const SINGUP_ERROR_MSG = 'Provider or email is already in use';
export const LINK_FLOW = 'link';

export const POSITION_ICON = {
	1: 'attacker-role-icon.png',
	2: 'defender-role-icon.png',
	3: 'specialist-role-icon.png',
}