import axios from 'axios';

import {
	IUserParams,
	IMokensUser,
	IServerResponse,
	IWalletParams,
	IWalletSignatureParams,
	IDynamoUser,
	IEmailParams,
	IUpdateUserParams,
	IProviderParams,
  IAlternateServerResponse,
} from './types';

// Base axios Instance
const http = axios.create({
	baseURL: process.env.GATSBY_BASE_API_URL,
	headers: {
		'Content-type': 'application/json',
	},
});

export const linkWallet = async (
	params: IWalletSignatureParams
): Promise<boolean | undefined> => {
	try {
		const resp = await http.post<IServerResponse>(`/updateUser`, params);
		return resp.data.body.success;
	} catch (err) {
		console.error(err);
		return;
	}
};

export const updateUser = async (
	params: IUpdateUserParams
): Promise<boolean | undefined> => {
	try {
		const resp = await http.post<IServerResponse>(`/updateUser`, params);
		return resp.data.body.success;
	} catch (err) {
		console.error(err);
		return;
	}
};

export const walletStatus = async (
	params: IWalletParams
): Promise<IDynamoUser | undefined> => {
	try {
		const resp = await http.get<IServerResponse>(
			`/wallet-linked?wallet=${params.wallet}`
		);
		if (resp.data.body.success) {
			return resp.data.body.data as IDynamoUser;
		}
	} catch (err) {
		console.error(err);
		return;
	}
};

export const emailStatus = async (
	params: IEmailParams
): Promise<IDynamoUser | undefined> => {
	try {
		const resp = await http.get<IServerResponse>(
			`/getEmail?email=${params.email}`
		);
		if (resp.data.body.success) {
			return resp.data.body.data as IDynamoUser;
		}
	} catch (err) {
		console.error(err);
		return;
	}
};

export const providerStatus = async (
	params: IProviderParams
): Promise<IDynamoUser | undefined> => {
	try {
		const resp = await http.get<IServerResponse>(
			`/getProvider?provider=${params.provider}&providerId=${params.providerId}`
		);
		if (resp.data.body.success) {
			return resp.data.body.data as IDynamoUser;
		}
	} catch (err) {
		console.error(err);
		return;
	}
};

export const sendMagicLinkCode = async (
	params: IUserParams
): Promise<boolean | undefined> => {
	try {
		const resp = await http.post<IServerResponse>(
			`/getPasswordlessOtp`,
			params
		);
		return resp.data.body.success;
	} catch (err) {
		console.error(err);
		return;
	}
};

export const validateMagicLinkCode = async (
	params: IUserParams
): Promise<IMokensUser | undefined> => {
	try {
		const resp = await http.post<IServerResponse>(
			`/validatePasswordlessOtp`,
			params
		);
		if (resp.data.body.success) return resp.data.body.data as IMokensUser;
		else return undefined;
	} catch (err) {
		console.error(err);
		return;
	}
};

export const unlinkProvider = async (params) => {
	try {
		const resp = await http.get<IServerResponse>(
			`/social-unlink?id=${params.id}&provider=${params.provider}&access_token=${params.access_token}&dbId=${params.dbId}`
		);
		if (resp.data.body.success) {
			return true;
		}
	} catch (err) {
		console.error(err);
		return;
	}
};

export const addEmailToSendpulse = async (params) => {
  try {
    const resp = await http.post<IAlternateServerResponse>(
      `/betaRegister`,
      params
    );
    return resp.data.success;
  } catch (err) {
    console.error(err);
    return;
  }
}

// The 'webignore' parameter indicates to the API that only web mokens
// should be returned, otherwise it returns all mokens
export const getAllMokens = async (webignore: boolean = true) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${window.localStorage.mluser_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    }
  }

  const body = {
    'id_token': window.localStorage.mluser_ID_TOKEN,
    'webignore': webignore
  };

  try {
    const resp = await http.post(
      `/getMokensInfo`,
      body,
      config
    );
    return resp.data;
  } catch (err) {
    console.error(err);
    return;
  }
}

export const getMokensFamily = async (family: string, webignore: boolean = true) => {
  const config = {
    headers: {
      'Authorization': `Bearer ${window.localStorage.mluser_ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    }
  }

  const body = {
    'id_token': window.localStorage.mluser_ID_TOKEN,
    'webignore': webignore,
    'family': family
  };

  try {
    const resp = await http.post(
      `/getMokensInfo`,
      body,
      config
    );
    return resp.data;
  } catch (err) {
    console.error(err);
    return;
  }
}
