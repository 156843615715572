import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';

import { POLYGON_NETWORK_CHAIN_ID, USER_LOCAL_STORAGE_KEY } from './const';
import { walletStatus } from './server';

function handleAccountChange(accounts: string[]) {
	localStorage.removeItem(USER_LOCAL_STORAGE_KEY + '_WALLET');
}
function handleChainChange(chainId: string) {
	localStorage.removeItem(USER_LOCAL_STORAGE_KEY + '_WALLET');
}

export default class WalletManager {
	_baseProvider = null;
	_walletProvider = null;
	_signer = null;
	_connectedWallet = null;
	_setAddress = null;

	constructor(setAddress) {
		this._setAddress = setAddress;
	}

	init = async () => {
		try {
			this._baseProvider = await detectEthereumProvider();
			if (this._baseProvider) {
				this._walletProvider = new ethers.providers.Web3Provider(
					this._baseProvider
				);
				this._signer = this._walletProvider.getSigner();

				this._baseProvider.on('accountsChanged', (accounts) => {
					handleAccountChange(accounts);
					this._setAddress(null);
				});
				this._baseProvider.on('chainChanged', (chainId) => {
					handleChainChange(chainId);
					this._setAddress(null);
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	public get walletProvider() {
		return this._walletProvider;
	}

	public get baseProvider() {
		return this._baseProvider;
	}

	connect = async () => {
		if (this._baseProvider) {
			await this._baseProvider.request({
				method: 'eth_requestAccounts',
			});
		}
		const address = await this.getAddress();
		localStorage.setItem(USER_LOCAL_STORAGE_KEY + '_WALLET', address);
		this._setAddress(address);
		return address;
	};

	isConfigured = (): boolean => {
		return this._walletProvider !== null;
	};

	isConnected = (): boolean => {
		return this._baseProvider.isConnected();
	};

	getNetwork = async () => {
		if (this._walletProvider) {
			return await this._walletProvider.getNetwork();
		}
	};

	getAddress = async () => {
		if (this._walletProvider && this._signer) {
			try {
				const address: string = await this._signer.getAddress();
				this._connectedWallet = address;
				return this._connectedWallet;
			} catch {
				return;
			}
		}
	};

	getWalletStatus = async () => {
		if (this._signer) {
			const address = await this.getAddress();
			if (address) {
				return await walletStatus({ wallet: address });
			}
			return;
		}
	};

	getSignature = async (message) => {
		if (this._signer) {
			const signature = await this._signer.signMessage(message);
			return signature;
		}
	};

	getSignedUpUser = async () => {
		const network = await this.getNetwork();
		if (
			this.isConfigured() &&
			this.isConnected() &&
			network.chainId == POLYGON_NETWORK_CHAIN_ID
		) {
			const signedUpUser = await this.getWalletStatus();
			return signedUpUser;
		}
		return null;
	};

	clear = () => {
		this._baseProvider.removeListener('accountsChanged');
		this._baseProvider.removeListener('chainChanged');
	};
}
