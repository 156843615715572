import React, {
	createContext,
	FC,
	useContext,
	useEffect,
	useState,
} from 'react';
import jwtDecode from 'jwt-decode';

import WalletManager from './wallet';
import {
  emailStatus,
	sendMagicLinkCode,
	updateUser,
	validateMagicLinkCode,
} from './server';
import { USER_LOCAL_STORAGE_KEY } from './const';
import { IBetaRegisterData, IUserContextState, IUserProviderProps } from './types';

const UserContext = createContext<IUserContextState>({
	user: null,
	setUser: null,
	walletManager: null,
	sendCode: null,
	validateCode: null,
	address: null,
	loaded: null,
	logout: null,
	update: null,
  betaRegisterData: null,
  setBetaRegisterEmail: null,
  setDevice: null,
  resetRegisterBetaData: null,
});

const defaultRegisterBetaData = {
  emailAddress: '',
  selectedDevice: ''
}

export const useUserContext = () => useContext(UserContext);

const UserProvider: FC<IUserProviderProps> = ({ children }) => {
	const [user, setUser] = useState(null);
	const [address, setAddress] = useState(null);
	const [walletManager] = useState(new WalletManager(setAddress));
  const [betaRegisterData, setBetaRegisterData] = useState<IBetaRegisterData>(defaultRegisterBetaData);
	const [loaded, setLoaded] = useState<boolean | null>(null)

	const sendCode = async (email: string): Promise<boolean> => {
		const resp = await sendMagicLinkCode({ email: email });
		return resp;
	};

	const validateCode = async (
		email: string,
		code: string,
		mailingList?: boolean
	) => {
		setLoaded(false)
		const resp = await validateMagicLinkCode({ email, otp: code, mailingList });
		if (resp) {
			localStorage.setItem(USER_LOCAL_STORAGE_KEY + '_EMAIL', email);
			localStorage.setItem(
				USER_LOCAL_STORAGE_KEY + '_REFRESH_TOKEN',
				resp.refresh_token
			);
			localStorage.setItem(
				USER_LOCAL_STORAGE_KEY + '_ACCESS_TOKEN',
				resp.access_token
			);
			localStorage.setItem(USER_LOCAL_STORAGE_KEY + '_ID_TOKEN', resp.id_token);
			const userInfo = await emailStatus({ email: email });
			const signedUser = {
				...userInfo,
				id_token: resp.id_token,
				refresh_token: resp.refresh_token,
				access_token: resp.access_token,
			};
			setUser(signedUser);
			setLoaded(true)
			return signedUser;
		}
	};

	const logout = async () => {
		localStorage.removeItem(USER_LOCAL_STORAGE_KEY + '_EMAIL');
		localStorage.removeItem(USER_LOCAL_STORAGE_KEY + '_REFRESH_TOKEN');
		localStorage.removeItem(USER_LOCAL_STORAGE_KEY + '_ACCESS_TOKEN');
		localStorage.removeItem(USER_LOCAL_STORAGE_KEY + '_ID_TOKEN');
		setUser(null);
	};

	const init = async () => {
		setLoaded(false)

		try {
			await walletManager.init();

			const userEmail = localStorage.getItem(USER_LOCAL_STORAGE_KEY + '_EMAIL');
			const userRefreshToken = localStorage.getItem(
				USER_LOCAL_STORAGE_KEY + '_REFRESH_TOKEN'
			);
			const userAccessToken = localStorage.getItem(
				USER_LOCAL_STORAGE_KEY + '_ACCESS_TOKEN'
			);
			const userIdToken = localStorage.getItem(
				USER_LOCAL_STORAGE_KEY + '_ID_TOKEN'
			);

			let decodedIdToken;
			if (userIdToken) decodedIdToken = jwtDecode(userIdToken);

			if (decodedIdToken && decodedIdToken.exp > Date.now().valueOf() / 1000) {
				const currentUser = await emailStatus({ email: userEmail });
				if (currentUser) {
					setUser({
						...currentUser,
						id_token: userIdToken,
						access_token: userAccessToken,
						refresh_token: userRefreshToken,
					});
				}
			} else {
				localStorage.clear();
			}
			setLoaded(true)
		} catch (error) {
			console.error(error);
			setLoaded(true)
		}
	};

	const update = async (params: {
		dbId: string;
		email?: string;
		nickname?: string;
		wallet?: string;
		removeWallet?: boolean;
		icon?: string;
		mailingList?: boolean;
	}) => {
		setLoaded(false)

		try {
			let resp;
			if (params.email) {
				const decodedIdToken = jwtDecode(user.id_token) as any;
				resp = await updateUser({
					dbId: params.dbId,
					email: params.email,
					authId: decodedIdToken.sub,
				});
				if (resp) {
					localStorage.setItem(USER_LOCAL_STORAGE_KEY + '_EMAIL', params.email);
					setUser({
						...user,
						userEmail: params.email,
					});
				}
			}
			if (params.nickname) {
				resp = await updateUser({
					dbId: params.dbId,
					nickname: params.nickname,
				});
				if (resp)
					setUser({
						...user,
						userNickname: `${params.nickname}#${user.nonce}`,
					});
			}
			if (params.wallet) {
				const message: string = `${user.nonce}:${user.userEmail}`;
				const signature = await walletManager.getSignature(message);

				resp = await updateUser({
					dbId: params.dbId,
					wallet: params.wallet,
					signature,
				});
				if (resp)
					setUser({
						...user,
						userWallet: params.wallet,
					});
			}
			if (params.removeWallet) {
				resp = await updateUser({
					dbId: params.dbId,
					removeWallet: params.removeWallet,
				});
				if (resp)
					setUser({
						...user,
						userWallet: params.wallet,
					});
			}
			if (params.icon) {
				resp = await updateUser({ dbId: params.dbId, icon: params.icon });
				if (resp)
					setUser({
						...user,
						userIcon: params.icon,
					});
			}
			if (params.mailingList !== undefined) {
				resp = await updateUser({
					dbId: params.dbId,
					mailingList: params.mailingList,
				});
				if (resp)
					setUser({
						...user,
						mailingList: params.mailingList,
					});
			}
			setLoaded(true)
			return resp;
		} catch (error) {
			console.error(error);
			setLoaded(true)
		}
	};

  const setBetaRegisterEmail = (emailAddress: string) => {
    setBetaRegisterData((prevBetaRegisterData) => ({
      ...prevBetaRegisterData,
      emailAddress
    }));
  };

  const setDevice = (selectedDevice: string) => {
   setBetaRegisterData((prevBetaRegisterData) => ({
      ...prevBetaRegisterData,
      selectedDevice
    }));
  };

  const resetRegisterBetaData = () => setBetaRegisterData(defaultRegisterBetaData);

	useEffect(() => {
		init();
	}, []);

	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
				walletManager,
				sendCode,
				validateCode,
				loaded,
				logout,
				address,
				update,
        betaRegisterData,
        setBetaRegisterEmail,
        setDevice,
        resetRegisterBetaData,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;
